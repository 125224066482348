

export default function Header() {

    return(
        <header>
            <img src="/logo.png" loading="lazy" />
            <h1>
                ChoiceScarf 
                <span>Stop wasting speed EVs</span>
            </h1>
        </header>
    )
}